export const TrendData = [

{
    name: "CrossFit",
    shares:"287",
},
{
    name: "ChrisHemsworth",
    shares:"216",
},
{
    name: "Bodybuilding",
    shares:"186",
},
{
    name: "David Laid",
    shares:"154",
},
{
    name: "FitnessMotivation",
    shares:"93",
}
]